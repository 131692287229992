import { useParams } from 'react-router';
import { data } from '../static/postData';
import '../static/bgSvg.css';
import Header from '../componants/Header';
import Footer from '../componants/Footer';

//---------test---parser---

export default function Post() {
	const params = useParams();

	return (
		<div className="w-full h-full bg">
			<div>
				<Header />
			</div>
			<div className="flex flex-col">
				<div className="flex flex-row pt-32">
					<div className="w-1/2 h-3/6   ">
						<img className=" ml-9  shadow-xl hidden  xl:block " src={data.img} alt="photo" />
					</div>
					<div className="w-1/2 m-9  xl:w-full h-1/4 max-w-2xl  ">
						<h1 className="text-5xl font-bold pt-48  px-6 text-gray-700 ">{data.title}</h1>
						<p className="text-1xl font-bold pt-16 px-3 text-gray-700 ">
							Writte By <span className=" text-green-900 font-extralight">{data.name}</span> On
							<span>{data.date}</span>
						</p>
					</div>
				</div>
			</div>
			<div>
				<div className="px-96 h-auto transform -translate-y-28 ">
					{/* {data && data.map((item) => parseHTML(item.p))} */}
					<div className=" max-w-7xl bg-white rounded-2xl pl-28">
						<div dangerouslySetInnerHTML={{ __html: data.p }} />
					</div>
				</div>
				<div />
			</div>
			<div className="bg-gray-100">
				<Footer />
			</div>
		</div>
	);
}
