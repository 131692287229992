import React from 'react';

function Footer() {
	return (
		<div className="flex  h-full w-full items-center justify-center space-x-4 text-gray-500 text-2xl cursor-pointer">
			<div>About</div>
			<div>Privacy</div>
			<div>Contact</div>
			<div>All Rights are Reserved</div>
		</div>
	);
}

export default Footer;
