import Card from '../componants/Card';
import Galary from '../componants/Galary';
import { data } from '../static/data';
import '../static/bgSvg.css';
import Footer from '../componants/Footer';
import Header from '../componants/Header';

function Front() {
	return (
		<div className=" bg-gray-100 h-full  flex flex-col bg  ">
			<Header />
			{data.length !== 0 ? (
				<div>
					<div>
						<div className="">
							<h1 className=" text-center text-5xl  mt-10 text-gray-800 bg-gray-100 transform duration-150 hover:scale-110 ">
								{data.title}
							</h1>
						</div>
						<div>
							<h2 className=" mx-auto text-center text-2xl font-bold font-serif max-w-2xl m-16 bg-gray-100 ">
								{data.bio}
							</h2>
						</div>
					</div>
					<div>
						<div className="flex justify-center items-center">
							<input
								className="w-96 h-12  rounded-xl p-1 focus:outline-none text-gray-800 focus:ring-2 ring-green-400 "
								placeholder="Search"
							/>
						</div>
						<div className=" flex  flex-row flex-wrap justify-center sm:px-10 md:px-80 py-10 ">
							{data.card.map((data) => <Card props={data} />)}
						</div>
					</div>
				</div>
			) : (
				'Ss'
			)}
			<div className="flex  flex-col justify-center it">
				<div className="h-full">
					<Galary />
				</div>
				<div className="h-40 w-full mt-60 ">
					<Footer />
				</div>
			</div>
		</div>
	);
}

export default Front;
